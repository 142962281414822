import React from 'react'
import { useSelector } from 'react-redux';

function Home() {

  const { user } = useSelector(state => state.auth)
  console.log(user, 'user')

  return (
          <>
          <p>Welcome, {user.name}!</p> 
          <p>Display Name: {user.display_name ?? 'Not Available'}</p>
          </>
          )
}

export default Home

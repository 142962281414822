import { useState } from 'react';
import useAutoLogin from 'hooks/use-auto-login';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import InputField from 'components/ui-elements/input-field';

import { loginSchema } from 'configs/yup-validation-schemas';

import { asyncLogin } from 'services/reqres/requests';
import FaceAuth from './face-auth';

function Login() {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedTab, setSelectedTab] = useState('traditional')

  const resolver = useYupValidationResolver(loginSchema);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver });

  const onSubmit = (data) => {
    setIsSubmitting(true);
    try {
      dispatch(asyncLogin({ user_name: data.user_name, password: data.password }));
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
    //setIsSubmitting(false);
  };

  useAutoLogin(); // will redirect to '/' or '/from-path' if user is logged in

  return (
    <div className="Login flex min-h-full flex-col justify-center items-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        
        <div className="flex justify-center mb-4">
          <button
            className={`px-4 py-2 ${selectedTab === 'traditional' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} rounded-l`}
            onClick={() => setSelectedTab('traditional')}
          >
            Credentials Login
          </button>
          <button
            className={`px-4 py-2 ${selectedTab === 'facial' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} rounded-r`}
            onClick={() => setSelectedTab('facial')}
          >
            Facial Login
          </button>
        </div>
        {selectedTab === 'traditional' ? (
        <form onSubmit={handleSubmit(onSubmit)} className="login-form space-y-6">
          <InputField
            label="Username"
            name="user_name"
            type="text"
            autoComplete={'current-email'}
            error={errors.user_name}
            {...register('user_name')}
          />

          <InputField
            label="Password"
            name="password"
            type="password"
            autoComplete={'current-password'}
            error={errors.password}
            {...register('password')}
          />

          <input
            type="submit"
            value={isSubmitting ? "Signing in..." : "Sign in"}
            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            disabled={isSubmitting}
          />
        </form>) : <FaceAuth /> }
        
      </div>
    </div>
  );
}

export default Login;
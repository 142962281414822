import requestURLs from './urls'
import {jwtDecode} from 'jwt-decode'
import { login, logout } from 'store/auth'

const asyncRegister =
  ({ firstName, lastName, email, password }) =>
  async dispatch => {
    const requestURL = requestURLs.register
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ firstName, lastName, email, password }),
    }
    const response = await fetch(requestURL, requestOptions)
    const data = await response.json()
    dispatch(login({ user: { id: 1 }, token: data.token })) // TODO -> update user object
  }

const asyncLogin =
  ({ user_name, password }) =>
  async dispatch => {

    const requestURL = requestURLs.login
    
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user_name, password }),
    }
    const response = await fetch(requestURL, requestOptions)
    const data = await response.json()

    if( !response.ok ) throw new Error(data.message) || 'Something went wrong';
    
    const decodedToken = jwtDecode(data.access_token)
    dispatch(login({ user: { id: 1, name : decodedToken.name, display_name : decodedToken.display_name }, token: data.access_token }))

  }

export const facialRegistration = async ( { image } ) => {
  
  const requestURL = requestURLs.facial_registration
    
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` },
    body: JSON.stringify({ image }),
  }

  const response = await fetch(requestURL, requestOptions)
  const data = await response.json()

  if( !response.ok ) throw new Error(data.message) || 'Something went wrong';

  return data;

}

export const facialAuth = async ( { image } ) => {
  
  const requestURL = requestURLs.facial_auth
    
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ image }),
  }

  const response = await fetch(requestURL, requestOptions)
  const data = await response.json()

  if( !response.ok ) throw new Error(data.message) || 'Something went wrong';

  const decodedToken = jwtDecode(data.access_token)
  //dispatch(login({ user: { id: 1, name : decodedToken.name }, token: data.access_token }))
  return { user: { id: 1, name : decodedToken.name, display_name : decodedToken.display_name }, token: data.access_token };

}

const asyncLogout = () => async dispatch => {
  // TODO -> call logout API here if needed
  return dispatch(logout())
}

export { asyncRegister, asyncLogin, asyncLogout }

import { prefixBaseURL } from 'utils/services'

const baseURL = 'http://ldap.anonid.io:5000'
//const baseURL = 'http://localhost:8001'

const requestPaths = {
  login: '/auth/login',
  register: '/register',
  user: '/user',
  facial_registration: '/auth/register/facial',
  facial_auth: '/auth/login/facial',
}

export default prefixBaseURL(requestPaths, baseURL)

// FaceAuth.js
import React, { useRef, useState } from 'react';
import { facialRegistration, facialAuth } from 'services/reqres/requests';
import { useDispatch } from 'react-redux';
import { login } from 'store/auth'

const FaceAuth = ( { type } ) => {
    
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const videoRef = useRef(null);

    // Initialize the webcam
    const startWebcam = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        videoRef.current.srcObject = stream;
    };

    // Capture an image from the webcam
    const captureImage = () => {
        const canvas = document.createElement('canvas');
        const video = videoRef.current;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
        return canvas.toDataURL('image/png').split(',')[1];
    };

    const stopWebcam = () => {
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach(track => track.stop());
        videoRef.current.srcObject = null;
    };

    // Send image to Flask for processing
    const handleSubmit = async () => {
        setErrorMessage(''); 
        setSuccessMessage('');
        try {
            const image = captureImage();
            const response = await facialRegistration({ image });
            console.log(response);
            setSuccessMessage('Registration successful!');
            stopWebcam();
        }catch(error){
            setErrorMessage(error.message); 
            //console.log(error, 'error');
        }
        
    };
    
    const handleLoginSubmit = async () => {
        setIsLoading(true);
        setErrorMessage(''); 
        try {
            
            const image = captureImage();
            const response = await facialAuth({ image });
            dispatch(login(response));
            stopWebcam();

        }catch(error){
            setErrorMessage('Error ! ' + error.message);
            //console.log(error, 'error');
        }finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen">
            {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
                    <div className="loader"></div>
                </div>
            )}
            <video ref={videoRef} autoPlay width="500" height="300" className="mb-4"></video>
            <button 
                onClick={startWebcam} 
                className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300"
            >
                Start Webcam
            </button>
            {type == 'register' ? ( 
            <button 
                onClick={handleSubmit} 
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 transition duration-300"
            >
                Register
            </button>) : (
                <button 
                onClick={handleLoginSubmit} 
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 transition duration-300"
            >
                Login
            </button>
            ) }
            {errorMessage && (
                <div className="mt-4 text-red-500">
                    {errorMessage}
                </div>
            )}
            {successMessage && (
                <div className="mt-4 text-green-500">
                    {successMessage}
                </div>
            )}
        </div>
    );
};

export default FaceAuth;
